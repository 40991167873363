import { Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend } from "@angular/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs/Rx"

// operators
import "rxjs/add/operator/catch"
import "rxjs/add/observable/throw"
import "rxjs/add/operator/map"
import { Router } from "@angular/router";
import { AuthenticationService } from "../providers/auth.service";
import { MatSnackBar } from "@angular/material";

@Injectable()
export class HttpInterceptor extends Http {
    constructor(
        backend: XHRBackend,
        options: RequestOptions,
        public http: Http,
        private router: Router,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar
    ) {
        super(backend, options)
    }

    public request(request: Request, options?: RequestOptionsArgs): Observable<Response> {
        request.headers.set('Content-Type', 'aplication/json'); // todos los request son JSON
        request.headers.set('Authorization', 'Bearer ' + this.authenticationService.token); // todos los request necesitan el token 

        return super.request(request, options)
            .map(
                respuesta => {
                    // actualizamos con el nuevo token
                    var datos = respuesta.json();
                    if (datos.estado && datos.estado == 1 && datos.token) {
                        this.authenticationService.actualizarToken(datos.token); // siempre que se hace un request se actualiza el token
                    }

                    if (datos.estado && datos.estado != 1) {
                        this.snackBar.open(datos.mensaje, 'OK', { duration: 2750 });
                        throw datos.mensaje;
                    }

                    // retornar respuesta para la request original hecha por el sistema
                    return respuesta;
                })
            .catch(this.handleError)
    }

    public handleError = (error: Response) => {
        if (error.status == 401) { // no autorizado
            this.router.navigate(['/ingreso']); // salir del sistema
            this.snackBar.open('Es necesario volver a ingresar', 'OK', { duration: 2750 });
            return Observable.throw('Es necesario volver a ingresar');
        } else if (error.status == 0) {
            this.snackBar.open('ERROR: procesando la solicitud', 'OK');
        }

        // Do messaging and error handling here
        return Observable.throw(error)
    }
}