import { Component, Input, ElementRef, OnDestroy, AfterViewInit, Optional, Self, forwardRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatFormFieldControl } from '@angular/material';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FormGroup, FormBuilder, NgControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

export function telefonoValidator(control: AbstractControl): ValidationErrors {
  if (control && control.value) {
    if (control.value.pais == '' && control.value.area == '' && control.value.numero == '') {
      return <ValidationErrors>{
        required: true
      }
    }
  }

  return null;
}

/** Data structure for holding telephone number. */
export class Telefono {
  constructor(
    public pais: string,
    public area: string,
    public numero: string) { }

  getNumber(): number {
    let onlyNumbersPais = ((this.pais) ? (this.pais.replace(/\D/g, '')) : "") + "";
    let onlyNumbersArea = ((this.area) ? (this.area.replace(/\D/g, '')) : "") + "";
    let onlyNumbersNro = ((this.numero) ? (this.numero.replace(/\D/g, '')) : "") + "";

    return +(onlyNumbersPais + onlyNumbersArea + onlyNumbersNro);
  }

  getString(): string {
    let onlyNumbersPais = ((this.pais) ? (this.pais.replace(/\D/g, '')) : "") + "";
    let onlyNumbersArea = ((this.area) ? (this.area.replace(/\D/g, '')) : "") + "";
    let onlyNumbersNro = ((this.numero) ? (this.numero.replace(/\D/g, '')) : "") + "";
    
    return (onlyNumbersPais ? "+(" + onlyNumbersPais + ") " : "") + (onlyNumbersArea ? onlyNumbersArea + "-" : "") + onlyNumbersNro;
  }
}

@Component({
  selector: 'my-telefono-input',
  templateUrl: './telefono.component.html',
  styleUrls: ['./telefono.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => TelefonoInput)
    }
  ],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  }
})
export class TelefonoInput implements MatFormFieldControl<Telefono>, OnDestroy, AfterViewInit {
  static nextId = 0;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  _firstFocus = true;
  controlType = 'my-telefono-input';
  id = `my-telefono-input-${TelefonoInput.nextId++}`;
  describedBy = '';
  propagateChange = (_: any) => { };
  @Output() onChange = new EventEmitter();

  @ViewChild('telParts') telParts: ElementRef;

  get empty() {
    const { value: { pais, area, numero } } = this.parts;
    return !pais && !area && !numero;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get errorState(): boolean { return this._errorState; }
  set errorState(value: boolean) {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.updateValueAndValidity();
      this._errorState = value;
    }
  }
  private _errorState: boolean = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @Input()
  get value(): Telefono | null {
    const { value: { pais, area, numero } } = this.parts;
    if (pais.length || area.length || numero.length) {
      return new Telefono(pais, area, numero);
    }

    return new Telefono('', '', '');
  }
  set value(tel: Telefono | null) {
    const { pais, area, numero } = tel || new Telefono('', '', '');
    this.parts.setValue({ pais, area, numero });
  }

  // centralized change events from the inputs
  public onInternalInputChange(event: any) {
    this.propagateChange(this.value);
    this.onChange.emit();
  }

  public onInput(event: any) {
    this.propagateChange(this.value);
  }

  constructor(
    fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Setting the value accessor directly (instead of using the providers)
    // to avoid running into a circular import.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = fb.group({
      pais: '',
      area: '',
      numero: ''
    });

    fm.monitor(elRef.nativeElement, true).subscribe(
      origin => {
        this.focused = !!origin;
        this.stateChanges.next();

        if (this.ngControl && this.ngControl.control && !this._firstFocus) {
          if (this.ngControl.control.touched) {
            this.errorState = this.ngControl.control.invalid;
          }

          this.ngControl.control.markAsTouched();
        }

        if (this.focused && this._firstFocus) {
          this._firstFocus = false;
        }
      });
  }

  writeValue(value: Telefono): void {
    this.parts.reset();
    let newValue = new Telefono('', '', '');
    if (!!value && value instanceof Telefono) {
      newValue = value;
    }
    this.parts.setValue(newValue);
    this.propagateChange(newValue);
  }

  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // si nos interesa registrar cuando el usuario toca el input
  }

  ngAfterViewInit() {
    this.telParts.nativeElement.querySelector('input.pais').addEventListener('keyup', (event) => {
      if (event.target.value && event.target.value.length == 4) {
        this.changeFocusTo('input.area');
      }
    });

    this.telParts.nativeElement.querySelector('input.area').addEventListener('keyup', (event) => {
      if (event.target.value && event.target.value.length == 4) {
        this.changeFocusTo('input.numero');
      }

      if (event.target.value.length == 0 && event.keyCode == 8) {
        this.changeFocusTo('input.pais');
      }
    });

    this.telParts.nativeElement.querySelector('input.numero').addEventListener('keyup', (event) => {

      if (this.ngControl && this.ngControl.control) {
        this.ngControl.control.updateValueAndValidity();
        this.errorState = this.ngControl.control.invalid;
      }

      if (event.target.value.length == 0 && event.keyCode == 8) {
        this.changeFocusTo('input.area');
      }
    });
  }

  changeFocusTo(selector) {
    this.telParts.nativeElement.querySelector(selector).focus();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }
}
