import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../globals';
import 'rxjs/add/operator/map';
import { Http, Headers, Response } from '@angular/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatDialog } from '@angular/material';
import { Telefono } from '../components/telefono/telefono.component';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthenticationService implements CanActivate {
  IdUsuario: number;
  usuario: string;
  apellido: string;
  nombre: string;
  email: string;
  token: string;
  tipo: number;
  telefono: Telefono;
  puesto: string;

  constructor(
    private http: Http,
    private globals: Globals,
    private permissionsService: NgxPermissionsService,
    private materialDialogs: MatDialog,
    private router: Router
  ) {
    this.setCurrentUserData();
  }

  login(email: string, contrasena: string): Observable<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });

    return this.http
      .post(
        this.globals.webserver + 'apiLogin/login',
        JSON.stringify({
          username: email,
          password: contrasena
        }),
        { headers: headers })
      .map(
        (response: Response) => { // setear el token secreto
          let respuesta = response.json();

          if (respuesta.estado == 1 && respuesta.data) {
            // guardar datos de session
            var dataUsuario = respuesta.data.usuario;

            localStorage.setItem(
              'currentUser',
              JSON.stringify({
                IdUsuario: dataUsuario.id,
                usuario: dataUsuario.usuario,
                apellido: dataUsuario.apellido,
                nombre: dataUsuario.nombre,
                email: dataUsuario.email,
                token: respuesta.token,
                tipo: dataUsuario.tipo,
                telefono: <Telefono>{
                  pais: dataUsuario.telefono.pais,
                  area: dataUsuario.telefono.area,
                  numero: dataUsuario.telefono.numero
                },
                puesto: dataUsuario.puesto.descripcion
              }));

            this.setCurrentUserData();
          }

          return respuesta;
        });
  }

  logout(): void {
    // limpiar permisos
    this.permissionsService.flushPermissions();

    // limpiar datos de session
    this.IdUsuario = null;
    this.usuario = null;
    this.apellido = null;
    this.nombre = null;
    this.email = null;
    this.token = null;
    this.tipo = null;
    this.telefono = null;
    this.puesto = null;

    localStorage.removeItem('currentUser'); // limpiar token del almacenamiento local

    // cerrar todos los dialogs abiertos
    this.materialDialogs.closeAll();
  }

  setCurrentUserData(): void {
    var currentUser = JSON.parse(localStorage.getItem('currentUser')); // leer token guardado
    this.IdUsuario = currentUser && currentUser.IdUsuario;
    this.usuario = currentUser && currentUser.usuario;
    this.apellido = (currentUser && currentUser.apellido) || '';
    this.nombre = (currentUser && currentUser.nombre) || '';
    this.email = currentUser && currentUser.email;
    this.token = currentUser && currentUser.token;
    this.tipo = currentUser && currentUser.tipo;
    this.telefono = currentUser && currentUser.telefono && new Telefono(currentUser.telefono.pais, currentUser.telefono.area, currentUser.telefono.numero);
    this.puesto = currentUser && currentUser.puesto;
  }

  actualizarToken(tokenNuevo: string): void {
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        IdUsuario: this.IdUsuario,
        usuario: this.usuario,
        apellido: this.apellido,
        nombre: this.nombre,
        email: this.email,
        token: tokenNuevo,
        tipo: this.tipo,
        telefono: this.telefono,
        puesto: this.puesto
      }));

    this.setCurrentUserData();
  }

  recuperarContrasena(email: string) {
    let headers = new Headers({ 'Content-Type': 'application/json' });

    return this.http.post(
      this.globals.webserver + 'apiLogin/passwordOlvidada',
      JSON.stringify({
        email: email
      }),
      {
        headers: headers
      }
    ).map(
      (response: Response) => response.json()
    );
  }

  canActivate() {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/ingreso']);
    return false;
  }
}