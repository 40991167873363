/* Este servicio se usa para actualizar los colores de aquellos componentes
/* que no pueden hacerlo por si mismos usando sus archivos ".scss".
/* los colores se actualizaran cada vez que el usuario seleccione un tema diferente */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface TemaColores {
    primary: CSSStyleDeclaration;
    accent: CSSStyleDeclaration;
    warn: CSSStyleDeclaration;
    foreground: CSSStyleDeclaration;
}

@Injectable()
export class TemaService {
    temaChange: Subject<TemaColores> = new Subject<TemaColores>();
    private _colores: TemaColores;

    constructor() { }

    initTema() {
        // si no hay un tema default seleccionado
        if (!localStorage.getItem('theme')) {
            localStorage.setItem('theme', 'default-light-theme');
        }

        // inicializa el tema seleccionado por el usuario en el body
        document.body.className = localStorage.getItem('theme') || 'default-light-theme';
    }

    // recibe el tema seleccionado y lo envia a los subscriptores
    setTema(temaSeleccionado: string, colores: TemaColores) {
        this._colores = colores;

        // remover tema viejo
        document.body.classList.remove(localStorage.getItem('theme'));

        // guardar tema en local storage para la proxima carga
        localStorage.setItem('theme', temaSeleccionado);

        // aplicar tema al body para que se vean los cambios en los componentes
        document.body.classList.add(temaSeleccionado);

        // Emitir evento con paleta de colores:
        // ------------------------------------
        this.temaChange.next(colores);
    }

    getColores(): TemaColores {
        return this._colores;
    }
}