import { Component, OnInit } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TemaService } from './providers/tema.service';
import { Title } from "@angular/platform-browser";
import { Globals } from './globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private paginador: MatPaginatorIntl,
    private temaService: TemaService,
    private globals: Globals
  ) {
    // EMPRESA_COMPILADA 
    this.titleService.setTitle(this.globals.empresa.toUpperCase()); // título del sistema en el navegador
    this.favicon(this.globals.favicon);
    // /EMPRESA_COMPILADA 
  }

  ngOnInit(): void {
    // paginador de material, set labels en español
    this.paginador.firstPageLabel = "Primera";
    this.paginador.lastPageLabel = "Última";
    this.paginador.nextPageLabel = "Siguiente";
    this.paginador.previousPageLabel = "Anterior";
    this.paginador.itemsPerPageLabel = "Ítems por página";
    this.paginador.getRangeLabel = (page: number, pageSize: number, length: number) => { if (length == 0 || pageSize == 0) { return `0 de ${length}`; } length = Math.max(length, 0); const startIndex = page * pageSize; const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} de ${length}`; };

    // set tema de colores default
    this.temaService.initTema();
  }

  favicon(icono: string) {
    let link = <HTMLLinkElement>(document.querySelector("link[rel*='icon']") || document.createElement('link'));
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = 'assets/favicon/' + icono;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}