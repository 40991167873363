import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import {
  MatPaginatorIntl,
  MatDialogModule,
  MatSnackBarModule,
  MAT_DATE_LOCALE,
} from '@angular/material';
import { TemaService } from './providers/tema.service';
import { Globals } from './globals';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthenticationService } from './providers/auth.service';
import {
  BaseRequestOptions,
  HttpModule
} from '@angular/http';
import {
  HttpClientModule
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptor } from './interceptors/http-interceptor.service';
import { PermisoService } from './providers/permiso.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TablaProgramacionResponsiveComponent } from './forms/programacion/tabla-programacion-responsive/tabla-programacion-responsive.component';


@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    HttpModule,
    MatDialogModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    MatPaginatorIntl,
    TemaService,
    Globals,
    AuthenticationService,
    BaseRequestOptions,
    HttpInterceptor,
    PermisoService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es'
    },
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }