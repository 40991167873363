import { Permiso } from "./permiso";

export class Permisos {
    public LISTADO = <Permiso[]>[
        <Permiso>{
            IdPermiso: 0,
            permisoComponente: 'HOME',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 1,
            permisoComponente: 'PROSPECTOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 2,
            permisoComponente: 'USUARIOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 3,
            permisoComponente: 'PROSPECTO-CREAR',
            permisoAction: 'apiProspecto/crearProspecto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 4,
            permisoComponente: 'PROSPECTO-VER',
            permisoAction: 'apiProspecto/getProspectos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 5,
            permisoComponente: 'USUARIO-CREAR',
            permisoAction: 'apiUsuario/crearUsuario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 6,
            permisoComponente: 'USUARIO-VER',
            permisoAction: 'apiUsuario/getUsuarios',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 7,
            permisoComponente: 'TIPOS-USUARIO-VER',
            permisoAction: 'apiUsuario/getTiposUsuario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 8,
            permisoComponente: 'PAISES-VER',
            permisoAction: 'apiGenerales/getPaises',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 9,
            permisoComponente: 'INCIDENCIAS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 10,
            permisoComponente: 'INCIDENCIA-CREAR',
            permisoAction: 'apiIncidencia/crearIncidencia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 11,
            permisoComponente: 'TIPOS-INCIDENCIA-VER',
            permisoAction: 'apiIncidencia/getTiposIncidencia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 12,
            permisoComponente: 'INCIDENCIA-VER',
            permisoAction: 'apiIncidencia/getIncidencias',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 13,
            permisoComponente: 'PROSPECTO-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 14,
            permisoComponente: 'PROSPECTO-ELIMINAR',
            permisoAction: 'apiProspecto/deleteProspecto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 15,
            permisoComponente: 'PROSPECTO-EDITAR',
            permisoAction: 'apiProspecto/editarProspecto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 16,
            permisoComponente: 'PROSPECTO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 17,
            permisoComponente: 'TIPOS-INCIDENCIA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 18,
            permisoComponente: 'TIPO-INCIDENCIA-CREAR',
            permisoAction: 'apiIncidencia/crearTipoIncidencia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 19,
            permisoComponente: 'TIPO-INCIDENCIA-EDITAR',
            permisoAction: 'apiIncidencia/editarTipoIncidencia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 20,
            permisoComponente: 'TIPO-INCIDENCIA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 21,
            permisoComponente: 'INCIDENCIA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 22,
            permisoComponente: 'USUARIO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 23,
            permisoComponente: 'TIPO-INCIDENCIA-ELIMINAR',
            permisoAction: 'apiIncidencia/eliminarTipoIncidencia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 24,
            permisoComponente: 'CONTACTO-VER',
            permisoAction: 'apiContacto/getContactos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 25,
            permisoComponente: 'CONTACTO-CREAR',
            permisoAction: 'apiContacto/crearContacto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 26,
            permisoComponente: 'TIPOS-CONTACTO-VER',
            permisoAction: 'apiContacto/getTiposContacto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 27,
            permisoComponente: 'CONTACTO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 28,
            permisoComponente: 'CONTACTO-EDITAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 29,
            permisoComponente: 'CONTACTO-ELIMINAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 30,
            permisoComponente: 'PRODUCTOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 31,
            permisoComponente: 'PRODUCTO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 32,
            permisoComponente: 'PRODUCTO-VER',
            permisoAction: 'apiProducto/getProductos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 33,
            permisoComponente: 'PRODUCTO-CREAR',
            permisoAction: 'apiProducto/crearProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 34,
            permisoComponente: 'PRODUCTO-ELIMINAR',
            permisoAction: 'apiProducto/eliminarProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 35,
            permisoComponente: 'PRODUCTO-EDITAR',
            permisoAction: 'apiProducto/editarProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 36,
            permisoComponente: 'PRODUCTO-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 37,
            permisoComponente: 'TIPOS-PRODUCTO-VER',
            permisoAction: 'apiProducto/getTiposProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 38,
            permisoComponente: 'ESTADOS-PRODUCTO-VER',
            permisoAction: 'apiProducto/getEstadosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 39,
            permisoComponente: 'CARACTERISTICAS-TIPO-PRODUCTO-VER',
            permisoAction: 'apiCaracteristicaProducto/getCaracteristicasProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 40,
            permisoComponente: 'COTIZACION-CREAR',
            permisoAction: 'apiCotizacion/crearCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 41,
            permisoComponente: 'COTIZACION-VER',
            permisoAction: 'apiCotizacion/getCotizaciones',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 42,
            permisoComponente: 'TIPOS-COTIZACION-VER',
            permisoAction: 'apiCotizacion/getTiposCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 43,
            permisoComponente: 'ITEM-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 44,
            permisoComponente: 'ITEM-CREAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 45,
            permisoComponente: 'ITEM-ELIMINAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 46,
            permisoComponente: 'ACCESORIO-VER',
            permisoAction: 'apiAccesorio/getAccesorios',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 47,
            permisoComponente: 'ITEM-EDITAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 48,
            permisoComponente: 'COTIZACION-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 49,
            permisoComponente: 'COTIZACION-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 50,
            permisoComponente: 'ITEM-VER',
            permisoAction: 'apiCotizacion/getItemsCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 51,
            permisoComponente: 'ACCESORIOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 52,
            permisoComponente: 'ACCESORIO-EDITAR',
            permisoAction: 'apiAccesorio/editarAccesorio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 53,
            permisoComponente: 'ACCESORIO-ELIMINAR',
            permisoAction: 'apiAccesorio/eliminarAccesorio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 54,
            permisoComponente: 'ACCESORIO-CREAR',
            permisoAction: 'apiAccesorio/crearAccesorio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 55,
            permisoComponente: 'ACCESORIO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 56,
            permisoComponente: 'TIPOS-PRODUCTO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 57,
            permisoComponente: 'TIPO-PRODUCTO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 58,
            permisoComponente: 'TIPOS-PRODUCTO-VER',
            permisoAction: 'apiProducto/getTiposProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 59,
            permisoComponente: 'TIPO-PRODUCTO-CREAR',
            permisoAction: 'apiProducto/crearTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 60,
            permisoComponente: 'TIPO-PRODUCTO-EDITAR',
            permisoAction: 'apiProducto/editarTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 61,
            permisoComponente: 'TIPO-PRODUCTO-ELIMINAR',
            permisoAction: 'apiProducto/eliminarTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 62,
            permisoComponente: 'PRODUCTO-IMAGEN-GUARDAR',
            permisoAction: 'apiProducto/guardarFotosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 63,
            permisoComponente: 'PRODUCTO-IMAGENES-VER',
            permisoAction: 'apiProducto/getFotosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 64,
            permisoComponente: 'PRODUCTO-IMAGENES-EDITAR',
            permisoAction: 'apiProducto/editarFotosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 65,
            permisoComponente: 'PRODUCTO-IMAGENES-ELIMINAR',
            permisoAction: 'apiProducto/eliminarFotoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 66,
            permisoComponente: 'PRODUCTO-IMAGEN-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 67,
            permisoComponente: 'PRODUCTO-ARCHIVO-GUARDAR',
            permisoAction: 'apiProducto/guardarArchivosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 68,
            permisoComponente: 'PRODUCTO-ARCHIVOS-VER',
            permisoAction: 'apiProducto/getArchivosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 69,
            permisoComponente: 'PRODUCTO-ARCHIVOS-ELIMINAR',
            permisoAction: 'apiProducto/eliminarArchivoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 70,
            permisoComponente: 'PRODUCTO-ARCHIVO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 71,
            permisoComponente: 'PRODUCTO-ARCHIVOS-EDITAR',
            permisoAction: 'apiProducto/editarArchivoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 72,
            permisoComponente: 'COTIZACIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 73,
            permisoComponente: 'COTIZACION-EDITAR',
            permisoAction: 'apiCotizacion/editarCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 74,
            permisoComponente: 'ESTADOS-COTIZACION-VER',
            permisoAction: 'apiCotizacion/getEstadosCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 75,
            permisoComponente: 'TIPOS-CONDICION',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 76,
            permisoComponente: 'TIPOS-CONDICION-VER',
            permisoAction: 'apiCondicion/getTiposCondicion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 77,
            permisoComponente: 'TIPO-CONDICION-CREAR',
            permisoAction: 'apiCondicion/crearTipoCondicion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 78,
            permisoComponente: 'TIPO-CONDICION-EDITAR',
            permisoAction: 'apiCondicion/editarTipoCondicion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 79,
            permisoComponente: 'TIPO-CONDICION-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 80,
            permisoComponente: 'TIPO-CONDICION-ELIMINAR',
            permisoAction: 'apiCondicion/eliminarTipoCondicion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 81,
            permisoComponente: 'TIPOS-GARANTIA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 82,
            permisoComponente: 'TIPOS-GARANTIA-VER',
            permisoAction: 'apiCotizacion/getTiposGarantia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 83,
            permisoComponente: 'TIPO-GARANTIA-CREAR',
            permisoAction: 'apiCotizacion/crearTipoGarantia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 84,
            permisoComponente: 'TIPO-GARANTIA-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoGarantia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 85,
            permisoComponente: 'TIPO-GARANTIA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 86,
            permisoComponente: 'TIPO-GARANTIA-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoGarantia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 87,
            permisoComponente: 'COTIZACION-ARCHIVO-GUARDAR',
            permisoAction: 'apiCotizacion/guardarArchivosCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 88,
            permisoComponente: 'COTIZACION-ARCHIVOS-VER',
            permisoAction: 'apiCotizacion/getArchivosCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 89,
            permisoComponente: 'COTIZACION-ARCHIVOS-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarArchivoCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 90,
            permisoComponente: 'COTIZACION-ARCHIVO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 91,
            permisoComponente: 'COTIZACION-ARCHIVOS-EDITAR',
            permisoAction: 'apiCotizacion/editarArchivoCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 92,
            permisoComponente: 'TIPO-PRODUCTO-IMAGEN-GUARDAR',
            permisoAction: 'apiProducto/guardarFotosTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 93,
            permisoComponente: 'TIPO-PRODUCTO-IMAGENES-VER',
            permisoAction: 'apiProducto/getFotosTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 94,
            permisoComponente: 'TIPO-PRODUCTO-IMAGENES-EDITAR',
            permisoAction: 'apiProducto/editarFotosTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 95,
            permisoComponente: 'TIPO-PRODUCTO-IMAGENES-ELIMINAR',
            permisoAction: 'apiProducto/eliminarFotoTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 96,
            permisoComponente: 'TIPO-PRODUCTO-IMAGEN-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 97,
            permisoComponente: 'CARACTERISTICAS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 98,
            permisoComponente: 'CARACTERISTICA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 99,
            permisoComponente: 'TAREA-CREAR',
            permisoAction: 'apiTarea/crearTarea',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 100,
            permisoComponente: 'TAREA-EDITAR',
            permisoAction: 'apiTarea/editarTarea',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 101,
            permisoComponente: 'TAREA-ELIMINAR',
            permisoAction: 'apiTarea/eliminarTarea',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 102,
            permisoComponente: 'TAREA-VER',
            permisoAction: 'apiTarea/getTareas',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 103,
            permisoComponente: 'TAREA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 104,
            permisoComponente: 'TAREA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 105,
            permisoComponente: 'TAREA-RESPONSABLE-CREAR',
            permisoAction: 'apiTarea/agregarResponsable',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 106,
            permisoComponente: 'TAREA-RESPONSABLE-ELIMINAR',
            permisoAction: 'apiTarea/quitarResponsable',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 107,
            permisoComponente: 'TAREA-RESPONSABLES-VER',
            permisoAction: 'apiTarea/getResponsables',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 108,
            permisoComponente: 'TAREA-RESPONSABLE-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 109,
            permisoComponente: 'TAREA-RESPONSABLE-COMPLETAR',
            permisoAction: 'apiTarea/setCompletada',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 110,
            permisoComponente: 'CLIENTES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 111,
            permisoComponente: 'CLIENTE-VER',
            permisoAction: 'apiCliente/getClientes',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 112,
            permisoComponente: 'CLIENTE-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 113,
            permisoComponente: 'CLIENTE-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 114,
            permisoComponente: 'ENTIDAD-VER',
            permisoAction: 'apiProspecto/getEntidades',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 115,
            permisoComponente: 'COTIZACION-EMAIL',
            permisoAction: 'apiCotizacion/email',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 116,
            permisoComponente: 'CLIENTE-EDITAR',
            permisoAction: 'apiCliente/editarCliente',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 117,
            permisoComponente: 'ENTIDAD-RESPONSABLES-VER',
            permisoAction: 'apiEntidad/getResponsables',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 118,
            permisoComponente: 'ENTIDAD-RESPONSABLE-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 119,
            permisoComponente: 'ENTIDAD-RESPONSABLE-CREAR',
            permisoAction: 'apiEntidad/agregarResponsable',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 120,
            permisoComponente: 'ENTIDAD-RESPONSABLE-ELIMINAR',
            permisoAction: 'apiEntidad/quitarResponsable',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 121,
            permisoComponente: 'TIPOS-CARACTERISTICA-VER',
            permisoAction: 'apiCaracteristicaProducto/getTiposCaracteristicaProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 122,
            permisoComponente: 'CARACTERISTICA-CREAR',
            permisoAction: 'apiCaracteristicaProducto/crearCaracteristicaProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 123,
            permisoComponente: 'CARACTERISTICA-EDITAR',
            permisoAction: 'apiCaracteristicaProducto/editarCaracteristicaProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 124,
            permisoComponente: 'CARACTERISTICA-ELIMINAR',
            permisoAction: 'apiCaracteristicaProducto/eliminarCaracteristicaProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 125,
            permisoComponente: 'PROVINCIAS-VER',
            permisoAction: 'apiGenerales/getProvincias',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 126,
            permisoComponente: 'VALORES-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 127,
            permisoComponente: 'VALOR-CREAR',
            permisoAction: 'apiCaracteristicaProducto/crearValorCaracteristica',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 128,
            permisoComponente: 'VALOR-ELIMINAR',
            permisoAction: 'apiCaracteristicaProducto/eliminarValorCaracteristica',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 129,
            permisoComponente: 'VALOR-CREAR',
            permisoAction: 'apiCaracteristicaProducto/crearValorCaracteristica',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 130,
            permisoComponente: 'VALORES-VER',
            permisoAction: 'apiCaracteristicaProducto/getValoresCaracteristicaProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 131,
            permisoComponente: 'ESTADISTICA-COTIZACIONES-ESTADOS',
            permisoAction: 'apiEstadisticas/getCotizacionesEstados',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 132,
            permisoComponente: 'ESTADISTICA-COTIZACIONES-ESTADOS-MES',
            permisoAction: 'apiEstadisticas/getCotizacionesEstadosPorMes',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 133,
            permisoComponente: 'ESTADISTICA-TARJETAS',
            permisoAction: 'apiEstadisticas/getTarjetas',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 134,
            permisoComponente: 'ESTADISTICA-VALORES-DOLAR',
            permisoAction: 'apiEstadisticas/getValoresDolar',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 135,
            permisoComponente: 'TAREAS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 136,
            permisoComponente: 'TIPOS-PAGO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 137,
            permisoComponente: 'TIPOS-PAGO-VER',
            permisoAction: 'apiCotizacion/getTiposPago',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 138,
            permisoComponente: 'TIPO-PAGO-CREAR',
            permisoAction: 'apiCotizacion/crearTipoPago',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 139,
            permisoComponente: 'TIPO-PAGO-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoPago',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 140,
            permisoComponente: 'TIPO-PAGO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 141,
            permisoComponente: 'TIPO-PAGO-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoPago',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 142,
            permisoComponente: 'TIPOS-VALIDEZ',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 143,
            permisoComponente: 'TIPOS-VALIDEZ-VER',
            permisoAction: 'apiCotizacion/getTiposValidez',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 144,
            permisoComponente: 'TIPO-VALIDEZ-CREAR',
            permisoAction: 'apiCotizacion/crearTipoValidez',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 145,
            permisoComponente: 'TIPO-VALIDEZ-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoValidez',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 146,
            permisoComponente: 'TIPO-VALIDEZ-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 147,
            permisoComponente: 'TIPO-VALIDEZ-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoValidez',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 148,
            permisoComponente: 'TIPOS-MONEDA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 149,
            permisoComponente: 'TIPOS-MONEDA-VER',
            permisoAction: 'apiCotizacion/getTiposMoneda',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 150,
            permisoComponente: 'TIPO-MONEDA-CREAR',
            permisoAction: 'apiCotizacion/crearTipoMoneda',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 151,
            permisoComponente: 'TIPO-MONEDA-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoMoneda',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 152,
            permisoComponente: 'TIPO-MONEDA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 153,
            permisoComponente: 'TIPO-MONEDA-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoMoneda',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 154,
            permisoComponente: 'TIPOS-IMPUESTO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 155,
            permisoComponente: 'TIPOS-IMPUESTO-VER',
            permisoAction: 'apiCotizacion/getTiposImpuesto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 156,
            permisoComponente: 'TIPO-IMPUESTO-CREAR',
            permisoAction: 'apiCotizacion/crearTipoImpuesto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 157,
            permisoComponente: 'TIPO-IMPUESTO-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoImpuesto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 158,
            permisoComponente: 'TIPO-IMPUESTO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 159,
            permisoComponente: 'TIPO-IMPUESTO-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoImpuesto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 160,
            permisoComponente: 'TIPOS-PLAZO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 161,
            permisoComponente: 'TIPOS-PLAZO-VER',
            permisoAction: 'apiCotizacion/getTiposPlazo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 162,
            permisoComponente: 'TIPO-PLAZO-CREAR',
            permisoAction: 'apiCotizacion/crearTipoPlazo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 163,
            permisoComponente: 'TIPO-PLAZO-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoPlazo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 164,
            permisoComponente: 'TIPO-PLAZO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 165,
            permisoComponente: 'TIPO-PLAZO-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoPlazo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 166,
            permisoComponente: 'USUARIO-EDITAR',
            permisoAction: 'apiUsuario/editarUsuario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 167,
            permisoComponente: 'USUARIO-CAMBIAR-CONTRASENA',
            permisoAction: 'apiUsuario/cambiarPassword',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 168,
            permisoComponente: 'USUARIO-ESTADO-ACTIVAR',
            permisoAction: 'apiUsuario/setUsuarioActivo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 169,
            permisoComponente: 'USUARIO-ESTADO-INACTIVAR',
            permisoAction: 'apiUsuario/setUsuarioInactivo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 170,
            permisoComponente: 'TAREA-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 171,
            permisoComponente: 'PUESTOS-USUARIO-VER',
            permisoAction: 'apiUsuario/getTiposPuesto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 172,
            permisoComponente: 'TAREA-NOTIFICAR',
            permisoAction: 'apiTarea/notificar',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 173,
            permisoComponente: 'NOTICIAS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 174,
            permisoComponente: 'NOTICIA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 175,
            permisoComponente: 'NOTICIA-VER',
            permisoAction: 'apiNoticia/getNoticias',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 176,
            permisoComponente: 'NOTICIA-CREAR',
            permisoAction: 'apiNoticia/crearNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 177,
            permisoComponente: 'NOTICIA-EDITAR',
            permisoAction: 'apiNoticia/editarNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 178,
            permisoComponente: 'NOTICIA-ELIMINAR',
            permisoAction: 'apiNoticia/eliminarNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 179,
            permisoComponente: 'NOTICIA-IMAGEN-GUARDAR',
            permisoAction: 'apiNoticia/guardarFotosNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 180,
            permisoComponente: 'NOTICIA-IMAGENES-VER',
            permisoAction: 'apiNoticia/getFotosNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 181,
            permisoComponente: 'NOTICIA-IMAGENES-EDITAR',
            permisoAction: 'apiNoticia/editarFotosNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 182,
            permisoComponente: 'NOTICIA-IMAGENES-ELIMINAR',
            permisoAction: 'apiNoticia/eliminarFotoNoticia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 183,
            permisoComponente: 'NOTICIA-IMAGEN-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 184,
            permisoComponente: 'WEB',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 185,
            permisoComponente: 'INDEX',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 186,
            permisoComponente: 'NOTICIA-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 187,
            permisoComponente: 'CONTADOR-VER',
            permisoAction: 'apiIndex/getContadores',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 188,
            permisoComponente: 'CONTADOR-EDITAR',
            permisoAction: 'apiIndex/editarContador',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 189,
            permisoComponente: 'WEB-ADMINISTRAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 190,
            permisoComponente: 'COTIZACION-COPIAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 191,
            permisoComponente: 'COTIZACION-VER-OBRAS',
            permisoAction: 'apiCotizacion/getObras',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 192,
            permisoComponente: 'GALERIA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 193,
            permisoComponente: 'GALERIA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 194,
            permisoComponente: 'GALERIA-IMAGENES-VER',
            permisoAction: 'apiWeb/getGaleria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 195,
            permisoComponente: 'GALERIA-SUBIR',
            permisoAction: 'apiWeb/guardarFotosGaleria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 196,
            permisoComponente: 'GALERIA-IMAGEN-ELIMINAR',
            permisoAction: 'apiWeb/eliminarFotoGaleria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 197,
            permisoComponente: 'COTIZACION-ITEM-VER-TIPOS-MOSTRAR-PRECIO',
            permisoAction: 'apiCotizacion/getTiposMostrarPrecio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 198,
            permisoComponente: 'NOTIFICACIONES-VER',
            permisoAction: 'apiNotificacion/getNotificaciones',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 199,
            permisoComponente: 'PAIS-VER',
            permisoAction: 'apiGenerales/getPais',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 200,
            permisoComponente: 'COTIZACION-VER-ESTADO-DESCRIPCION',
            permisoAction: 'apiCotizacion/getEstadoDescripciones',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 201,
            permisoComponente: 'TIPOS-ENTREGA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 202,
            permisoComponente: 'TIPOS-ENTREGA-VER',
            permisoAction: 'apiCotizacion/getTiposEntrega',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 203,
            permisoComponente: 'TIPO-ENTREGA-CREAR',
            permisoAction: 'apiCotizacion/crearTipoEntrega',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 204,
            permisoComponente: 'TIPO-ENTREGA-EDITAR',
            permisoAction: 'apiCotizacion/editarTipoEntrega',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 205,
            permisoComponente: 'TIPO-ENTREGA-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 206,
            permisoComponente: 'TIPO-ENTREGA-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarTipoEntrega',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 207,
            permisoComponente: 'COTIZACION-VALIDAR-NUMERO',
            permisoAction: 'apiCotizacion/validarCotizacionNumero',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 208,
            permisoComponente: 'CONTACTO-WHATSAPP',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 209,
            permisoComponente: 'CARACTERISTICA-ORDEN',
            permisoAction: 'apiCaracteristicaProducto/ordenCaracteristica',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 210,
            permisoComponente: 'COMPETIDORES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 211,
            permisoComponente: 'COMPETIDORES-VER',
            permisoAction: 'apiCotizacion/getCompetidores',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 212,
            permisoComponente: 'COMPETIDOR-CREAR',
            permisoAction: 'apiCotizacion/crearCompetidor',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 213,
            permisoComponente: 'COMPETIDOR-EDITAR',
            permisoAction: 'apiCotizacion/editarCompetidor',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 214,
            permisoComponente: 'COMPETIDOR-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 215,
            permisoComponente: 'COMPETIDOR-ELIMINAR',
            permisoAction: 'apiCotizacion/eliminarCompetidor',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 216,
            permisoComponente: 'GALERIA-IMAGEN-EDITAR',
            permisoAction: 'apiWeb/editarFotoGaleria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 217,
            permisoComponente: 'GALERIA-ORDEN',
            permisoAction: 'apiWeb/ordenFotoGaleria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 218,
            permisoComponente: 'GALERIA-VIDEO-SUBIR',
            permisoAction: 'apiWeb/guardarVideoGaleria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 219,
            permisoComponente: 'TIPOS-CLIENTE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 220,
            permisoComponente: 'TIPOS-CLIENTE-VER',
            permisoAction: 'apiEntidad/getTiposCliente',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 221,
            permisoComponente: 'TIPO-CLIENTE-CREAR',
            permisoAction: 'apiEntidad/crearTipoCliente',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 222,
            permisoComponente: 'TIPO-CLIENTE-EDITAR',
            permisoAction: 'apiEntidad/editarTipoCliente',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 223,
            permisoComponente: 'TIPO-CLIENTE-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 224,
            permisoComponente: 'TIPO-CLIENTE-ELIMINAR',
            permisoAction: 'apiEntidad/eliminarTipoCliente',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 225,
            permisoComponente: 'PROSPECTO-ES-CLIENTE',
            permisoAction: 'apiProspecto/ProspectoACliente',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 226,
            permisoComponente: 'EXPORTAR-CLIENTES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 227,
            permisoComponente: 'EXPORTAR-PROSPECTOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 228,
            permisoComponente: 'EXPORTAR-COTIZACIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 229,
            permisoComponente: 'EXPORTAR-INCIDENCIAS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 230,
            permisoComponente: 'EXPORTAR-PRODUCTOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 231,
            permisoComponente: 'EXPORTAR-TAREAS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 232,
            permisoComponente: 'COTIZACION-PDF',
            permisoAction: 'apiCotizacion/descargarPdf',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 233,
            permisoComponente: 'OTS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 234,
            permisoComponente: 'OT-ALTA',
            permisoAction: 'apiOrdenTrabajo/crear',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 235,
            permisoComponente: 'OT-BAJA',
            permisoAction: 'apiOrdenTrabajo/eliminar',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 236,
            permisoComponente: 'OT-MODIFICACION',
            permisoAction: 'apiOrdenTrabajo/editar',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 237,
            permisoComponente: 'ARCHIVO-CARGAR',
            permisoAction: 'apiArchivo/cargar',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 238,
            permisoComponente: 'OT-GET',
            permisoAction: 'apiOrdenTrabajo/getOrdenes',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 239,
            permisoComponente: 'ARCHIVO-DESCARGAR',
            permisoAction: 'apiArchivo/descargar',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 240,
            permisoComponente: 'PRODUCCION',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 241,
            permisoComponente: 'PROGRAMACION',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 243,
            permisoComponente: 'PROGRAMACION-GET',
            permisoAction: 'apiProgramacion/getProgramaciones',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 244,
            permisoComponente: 'ORDENES-VENTA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 245,
            permisoComponente: 'ORDEN-VENTA-ASIGNAR-ARCHIVOS',
            permisoAction: 'apiCotizacion/asignarArchivosOV',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 246,
            permisoComponente: 'ASIGNACION-STOCK',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 247,
            permisoComponente: 'OV-ACEPTAR-RECHAZAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 248,
            permisoComponente: 'OT-ACEPTAR-RECHAZAR',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 249,
            permisoComponente: 'ORDEN-VENTA-ELIMINAR-ARCHIVOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 250,
            permisoComponente: 'ORDEN-TRABAJO-ELIMINAR-ARCHIVOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 251,
            permisoComponente: 'SUCURSALES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 252,
            permisoComponente: 'GET-SUCURSALES',
            permisoAction: 'apiSucursal/getSucursales',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 253,
            permisoComponente: 'CREAR-SUCURSAL',
            permisoAction: 'apiSucursal/cargarSucursal',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 254,
            permisoComponente: 'EDITAR-SUCURSAL',
            permisoAction: 'apiSucursal/editarSucursal',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 255,
            permisoComponente: 'EQUIPOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 256,
            permisoComponente: 'GET-EQUIPOS',
            permisoAction: 'apiEquipo/getEquipos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 257,
            permisoComponente: 'CREAR-EQUIPOS',
            permisoAction: 'apiEquipo/crearEquipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 258,
            permisoComponente: 'EDITAR-EQUIPOS',
            permisoAction: 'apiEquipo/editarEquipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 259,
            permisoComponente: 'GET-TIPOS-EQUIPO',
            permisoAction: 'apiEquipo/getEquipoTipos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 260,
            permisoComponente: 'CREAR-TIPO-EQUIPOS',
            permisoAction: 'apiEquipo/crearEquipoTipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 261,
            permisoComponente: 'EDITAR-TIPO-EQUIPOS',
            permisoAction: 'apiEquipo/editarEquipoTipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 262,
            permisoComponente: 'GET-EQUIPO-ESTADO',
            permisoAction: 'apiEquipo/getEquiposEstado',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 263,
            permisoComponente: 'SERVICIOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 264,
            permisoComponente: 'SERVICIO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 265,
            permisoComponente: 'SERVICIO-VER',
            permisoAction: 'apiProducto/getProductos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 266,
            permisoComponente: 'SERVICIO-CREAR',
            permisoAction: 'apiProducto/crearProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 267,
            permisoComponente: 'SERVICIO-ELIMINAR',
            permisoAction: 'apiProducto/eliminarProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 268,
            permisoComponente: 'SERVICIO-EDITAR',
            permisoAction: 'apiProducto/editarProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 269,
            permisoComponente: 'SERVICIO-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 270,
            permisoComponente: 'TIPOS-SERVICIO-VER',
            permisoAction: 'apiProducto/getTiposProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 271,
            permisoComponente: 'ESTADOS-SERVICIO-VER',
            permisoAction: 'apiProducto/getEstadosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 272,
            permisoComponente: 'CARACTERISTICAS-TIPO-SERVICIO-VER',
            permisoAction: 'apiCaracteristicaProducto/getCaracteristicasProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 273,
            permisoComponente: 'TIPOS-SERVICIO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 274,
            permisoComponente: 'TIPO-SERVICIO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 275,
            permisoComponente: 'TIPOS-SERVICIO-VER',
            permisoAction: 'apiProducto/getTiposProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 276,
            permisoComponente: 'TIPO-SERVICIO-CREAR',
            permisoAction: 'apiProducto/crearTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 277,
            permisoComponente: 'TIPO-SERVICIO-EDITAR',
            permisoAction: 'apiProducto/editarTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 278,
            permisoComponente: 'TIPO-SERVICIO-ELIMINAR',
            permisoAction: 'apiProducto/eliminarTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 279,
            permisoComponente: 'SERVICIO-IMAGEN-GUARDAR',
            permisoAction: 'apiProducto/guardarFotosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 280,
            permisoComponente: 'SERVICIO-IMAGENES-VER',
            permisoAction: 'apiProducto/getFotosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 281,
            permisoComponente: 'SERVICIO-IMAGENES-EDITAR',
            permisoAction: 'apiProducto/editarFotosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 282,
            permisoComponente: 'SERVICIO-IMAGENES-ELIMINAR',
            permisoAction: 'apiProducto/eliminarFotoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 283,
            permisoComponente: 'SERVICIO-IMAGEN-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 284,
            permisoComponente: 'SERVICIO-ARCHIVO-GUARDAR',
            permisoAction: 'apiProducto/guardarArchivosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 285,
            permisoComponente: 'SERVICIO-ARCHIVOS-VER',
            permisoAction: 'apiProducto/getArchivosProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 286,
            permisoComponente: 'SERVICIO-ARCHIVOS-ELIMINAR',
            permisoAction: 'apiProducto/eliminarArchivoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 287,
            permisoComponente: 'SERVICIO-ARCHIVO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 288,
            permisoComponente: 'SERVICIO-ARCHIVOS-EDITAR',
            permisoAction: 'apiProducto/editarArchivoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 289,
            permisoComponente: 'TIPO-SERVICIO-IMAGEN-GUARDAR',
            permisoAction: 'apiProducto/guardarFotosTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 290,
            permisoComponente: 'TIPO-SERVICIO-IMAGENES-VER',
            permisoAction: 'apiProducto/getFotosTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 291,
            permisoComponente: 'TIPO-SERVICIO-IMAGENES-EDITAR',
            permisoAction: 'apiProducto/editarFotosTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 292,
            permisoComponente: 'TIPO-SERVICIO-IMAGENES-ELIMINAR',
            permisoAction: 'apiProducto/eliminarFotoTipoProducto',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 293,
            permisoComponente: 'TIPO-SERVICIO-IMAGEN-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 294,
            permisoComponente: 'EXPORTAR-SERVICIOS',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 295,
            permisoComponente: 'TRABAJO-CREAR',
            permisoAction: 'apiTrabajo/crearTrabajo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 296,
            permisoComponente: 'TRABAJO-EDITAR',
            permisoAction: 'apiTrabajo/editarTrabajo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 297,
            permisoComponente: 'TRABAJO-ELIMINAR',
            permisoAction: 'apiTrabajo/getTrabajos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 298,
            permisoComponente: 'TRABAJO-VER',
            permisoAction: 'apiTrabajo/getTrabajos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 299,
            permisoComponente: 'AGENDA',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 300,
            permisoComponente: 'CONTADOR-AGENDA',
            permisoAction: 'apiTarea/contadorActividadesAgenda',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 301,
            permisoComponente: 'VINCULACION-CALENDAR',
            permisoAction: 'apiGoogle/getGoogleClient',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 302,
            permisoComponente: 'VERSIONES-COTIZACION',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 303,
            permisoComponente: 'VER-VERSIONES-COTIZACION',
            permisoAction: 'apiCotizacion/getCotizacionVersiones',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 304,
            permisoComponente: 'VER-VERSION-COTIZACION',
            permisoAction: 'apiCotizacion/getCotizacionVersion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 305,
            permisoComponente: 'ACCIONES-VERSION-COTIZACION',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 306,
            permisoComponente: 'GET-DIRECCION',
            permisoAction: 'apiGoogle/getCoordenadas',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 307,
            permisoComponente: 'ENTIDAD-ARCHIVO-GUARDAR',
            permisoAction: 'apiEntidad/guardarArchivosEntidad',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 308,
            permisoComponente: 'ENTIDAD-ARCHIVOS-VER',
            permisoAction: 'apiEntidad/getArchivosEntidad',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 309,
            permisoComponente: 'ENTIDAD-ARCHIVOS-ELIMINAR',
            permisoAction: 'apiEntidad/eliminarArchivoEntidad',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 310,
            permisoComponente: 'ENTIDAD-ARCHIVO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 311,
            permisoComponente: 'ENTIDAD-ARCHIVOS-EDITAR',
            permisoAction: 'apiEntidad/editarArchivoEntidad',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 312,
            permisoComponente: 'GUARDAR-VERSION-COTIZACION',
            permisoAction: 'apiCotizacion/guardarCotizacionDesdeVersion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 313,
            permisoComponente: 'ITEM-VERSION-VER',
            permisoAction: 'apiCotizacion/getCotizacionItemsVersion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 314,
            permisoComponente: 'CREAR-ESTADO-EQUIPOS',
            permisoAction: 'apiEquipo/crearEquipoEstado',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 315,
            permisoComponente: 'TIPO-EQUIPO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 316,
            permisoComponente: 'TIPOS-EQUIPO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 317,
            permisoComponente: 'EQUIPO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 318,
            permisoComponente: 'EQUIPO-DETALLE',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 319,
            permisoComponente: 'MOTIVOS-PERDIDA',
            permisoAction: 'apiCotizacion/getMotivosPerdida',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 320,
            permisoComponente: 'EQUIPO-ARCHIVOS-VER',
            permisoAction: 'apiEquipo/getArchivosEquipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 321,
            permisoComponente: 'EQUIPO-ARCHIVO-ACCIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 322,
            permisoComponente: 'EQUIPO-ARCHIVOS-ELIMINAR',
            permisoAction: 'apiEquipo/eliminarArchivoEquipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 323,
            permisoComponente: 'EQUIPO-ARCHIVOS-EDITAR',
            permisoAction: 'apiEquipo/editarArchivoEquipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 324,
            permisoComponente: 'EQUIPO-ARCHIVO-GUARDAR',
            permisoAction: 'apiEquipo/guardarArchivosEquipo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 325,
            permisoComponente: 'EDITAR-ESTADO-EQUIPOS',
            permisoAction: 'apiEquipo/editarEquipoEstado',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 326,
            permisoComponente: 'EDITAR-ESTADO-COTIZACION',
            permisoAction: 'apiCotizacion/actualizarEstadoCotizacion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 327,
            permisoComponente: 'EXPORTAR-TAREA-OUTLOOK',
            permisoAction: 'apiTarea/ExportarTareaOutlook',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 328,
            permisoComponente: 'ESTADISTICA-INDICES-COMERCIALES',
            permisoAction: 'apiEstadisticas/getIndicesComerciales',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 329,
            permisoComponente: 'ESTADISTICA-COTIZACIONES-PROVINCIA',
            permisoAction: 'apiEstadisticas/getCotizacionesXProvincia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 330,
            permisoComponente: 'ESTADISTICA-COTIZACIONES-POR-PRODUCTO',
            permisoAction: 'apiEstadisticas/getCotizacionesXProductoOServicio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 331,
            permisoComponente: 'ESTADISTICA-COTIZACIONES-POR-SERVICIO',
            permisoAction: 'apiEstadisticas/getCotizacionesXProductoOServicio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 332,
            permisoComponente: 'ESTADISTICA-COTIZACIONES-POR-USUARIO',
            permisoAction: 'apiEstadisticas/getCotizacionesXUsuario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 333,
            permisoComponente: 'REPORTE-MONTO-PRODUCTO-SERVICIO',
            permisoAction: 'apiEstadisticas/getReporteMontoXProductoOServicio',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 334,
            permisoComponente: 'REPORTE-COTIZACIONES-ENTIDAD',
            permisoAction: 'apiEstadisticas/getReporteCotizacionesXEntidad',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 335,
            permisoComponente: 'REPORTE-ENTIDADES-PROVINCIA',
            permisoAction: 'apiEstadisticas/getReporteEntidadesXProvincia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 336,
            permisoComponente: 'REPORTE-ENTIDADES-INDUSTRIA',
            permisoAction: 'apiEstadisticas/getReporteEntidadesXIndustria',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 337,
            permisoComponente: 'REPORTE-ENTIDADES-INCIDENCIAS',
            permisoAction: 'apiEstadisticas/getReporteClienteEIncidencias',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 338,
            permisoComponente: 'REPORTE-CONTACTOS-PROVINCIA',
            permisoAction: 'apiEstadisticas/getReporteContactosPorProvincia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 339,
            permisoComponente: 'REPORTE-EQUIPOS',
            permisoAction: 'apiEstadisticas/getReporteEquipos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 340,
            permisoComponente: 'ESTADISTICA-EQUIPOS-PROVINCIA',
            permisoAction: 'apiEstadisticas/getEquiposXProvincia',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 341,
            permisoComponente: 'TIPOS-CAMPO-VER',
            permisoAction: 'apiProtocolo/getTiposDato',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 342,
            permisoComponente: 'CAMPO-CREAR',
            permisoAction: 'apiProtocolo/crearCampo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 343,
            permisoComponente: 'CAMPO-VER',
            permisoAction: 'apiProtocolo/getCampos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 344,
            permisoComponente: 'CAMPO-EDITAR',
            permisoAction: 'apiProtocolo/editarCampo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 345,
            permisoComponente: 'CAMPO-ELIMINAR',
            permisoAction: 'apiProtocolo/eliminarCampo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 346,
            permisoComponente: 'SECCION-CREAR',
            permisoAction: 'apiProtocolo/crearSeccion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 347,
            permisoComponente: 'SECCION-VER',
            permisoAction: 'apiProtocolo/getSecciones',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 348,
            permisoComponente: 'SECCION-EDITAR',
            permisoAction: 'apiProtocolo/editarSeccion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 349,
            permisoComponente: 'SECCION-ELIMINAR',
            permisoAction: 'apiProtocolo/eliminarSeccion',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 350,
            permisoComponente: 'FORMULARIO-CREAR',
            permisoAction: 'apiProtocolo/crearFormulario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 351,
            permisoComponente: 'FORMULARIO-VER',
            permisoAction: 'apiProtocolo/getFormulario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 352,
            permisoComponente: 'FORMULARIO-EDITAR',
            permisoAction: 'apiProtocolo/editarFormulario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 353,
            permisoComponente: 'FORMULARIO-ELIMINAR',
            permisoAction: 'apiProtocolo/eliminarFormulario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 354,
            permisoComponente: 'PREPROTOCOLO-CREAR',
            permisoAction: 'apiProtocolo/crearPreprotocolo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 355,
            permisoComponente: 'PREPROTOCOLO-VER',
            permisoAction: 'apiProtocolo/getPreprotocolos',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 356,
            permisoComponente: 'PREPROTOCOLO-EDITAR',
            permisoAction: 'apiProtocolo/editarPreprotocolo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 357,
            permisoComponente: 'PREPROTOCOLO-CARGAR',
            permisoAction: 'apiProtocolo/getPreprotocolo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 358,
            permisoComponente: 'PREPROTOCOLO-GUARDAR',
            permisoAction: 'apiProtocolo/llenarPreprotocolo',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 359,
            permisoComponente: 'PREPROTOCOLO-ASIGNADO',
            permisoAction: 'apiProtocolo/getPreprotocolosAsignados',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 360,
            permisoComponente: 'PREPROTOCOLO',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 361,
            permisoComponente: 'ASIGNACIONES',
            permisoAction: null,
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 362,
            permisoComponente: 'PREPROTOCOLO-FINALIZAR',
            permisoAction: 'apiProtocolo/finalizarFormulario',
            permisoIdPermisoPadre: null
        },
        <Permiso>{
            IdPermiso: 363,
            permisoComponente: 'CAMPO-PRECARGA',
            permisoAction: 'apiProtocolo/setValorPredeterminado ',
            permisoIdPermisoPadre: null
        },
    ];

    constructor() { }
}