import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AuthenticationService } from './auth.service';
import { Permiso } from '../interfaces/permiso';
import { Globals, MODULOS } from '../globals';
import { Permisos } from '../interfaces/permisos';

export interface LlamadoPermisoAction {
  componente: string; // componente que solicita el "controller/action" que debe ejecutar
  interval: any; // interval que se crea para responder a su llamado
}

@Injectable()
export class PermisoService {
  public permisos: Permiso[] = []; // permisos cargados para el usuario logueado
  permisosCargados: boolean = false; // true si ya hay permisos cargados
  intervalGetPermisosArray: LlamadoPermisoAction[] = []; // array con llamados a get action
  intervalTienePermisosArray: LlamadoPermisoAction[] = []; // array con llamados a tiene permiso
  SIN_PERMISO: string = "SIN_PERMISO"; // flag si no tiene permiso
  ROL_DIRECTOR: number = 3;
  ROL_ADMINISTRADOR: number = 1;
  ROL_VENDEDOR: number = 2;
  ROL_OPERARIO: number = 4;
  ROL_TECNICO: number = 5;

  constructor(
    private authenticationService: AuthenticationService,
    private globals: Globals
  ) { }

  flushPermisos(): void {
    this.permisos = [];
  }

  // buscar en el listado de permisos el padre del permiso recibido
  getPermisoPadre(permiso: Permiso): Permiso {
    var permisoPadre = null;

    this.permisos.forEach(posiblePermisoPadre => {
      if (permiso.permisoIdPermisoPadre == posiblePermisoPadre.IdPermiso) { // si encontramos el padre
        permisoPadre = posiblePermisoPadre; // guardamos el padre
        return; // dejamos de recorrer, encontramos el padre
      }
    });

    return permisoPadre;
  }

  // genera el component tree, es decir,
  // para el componente recibido busca todos sus padres y padres de padres etc...
  getPermisoComponentTree(permiso: Permiso) {
    var componentTree = '';
    var permisoActual = permiso;

    var componentPadresPila: Permiso[] = [] // traer todos los padres del componente y guardarlos en una pila de permisos
    while (permisoActual.permisoIdPermisoPadre != null) { // mientras que exista un padre
      var permisoPadre = this.getPermisoPadre(permisoActual);
      componentPadresPila.push(permisoPadre); // guardar el padre en la pila
      permisoActual = permisoPadre;
    }

    // cuando ya tenemos la pila de padres cargada, generamos el component tree
    componentPadresPila.forEach(permisoPadre => {
      componentTree += permisoPadre.permisoComponente + '-';
    });

    return ((componentTree.length > 0) ? componentTree : '') + permiso.permisoComponente;
  }

  // formatea los permisos del servidor para poder usarlos en la libreria interna
  formatearPermisos(): string[] {
    var permisosReturn = null;

    if (this.permisos && this.permisos.length > 0) { // si tenemos permisos
      permisosReturn = [];
      this.permisos.forEach(permiso => { // para cada permiso generamos su arbol de permisos
        permisosReturn.push(this.getPermisoComponentTree(permiso));
      });
    }

    return permisosReturn;
  }

  // CARGAR PERMISOS DEL USUARIO LOGUEADO
  getPermisos(): Observable<any> {
    this.authenticationService.tipo = +this.authenticationService.tipo;

    this.permisosCargados = false;
    return new Observable((observer) => {
      setTimeout(() => {
        // listado de permisos asignados según el usuario
        let idsPermiso = [];
        /* ====================== */
        /* |      DIRECTOR      | */
        /* ====================== */
        if (this.authenticationService.tipo == this.ROL_DIRECTOR) {
          // array de ids de permisos asignados
          idsPermiso = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
            102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
            141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179,
            180, 181, 182, 183, 185, 186, 187, 188, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220,
            221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273,
            274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312,
            313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351,
            352, 353, 354, 355, 356, 357, 358, 359, 360, 362, 363];
        }
        /* ========================== */
        /* |      ADMINISTRADOR     | */
        /* ========================== */
        if (this.authenticationService.tipo == this.ROL_ADMINISTRADOR) {
          // array de ids de permisos asignados
          idsPermiso = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
            102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
            141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179,
            180, 181, 182, 183, 185, 186, 187, 188, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220,
            221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275,
            276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353,
            354, 355, 356, 357, 358, 359, 360, 362, 363];
        }
        /* ====================== */
        /* |      VENDEDOR      | */
        /* ====================== */
        if (this.authenticationService.tipo == this.ROL_VENDEDOR) {
          idsPermiso = [0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
            102, 103, 104, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146,
            147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 185, 186, 187,
            188, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228,
            229, 230, 231, 232, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286,
            287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326,
            327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 362, 363];
        }
        /* ====================== */
        /* |      OPERARIO      | */
        /* ====================== */
        if (this.authenticationService.tipo == this.ROL_OPERARIO) {
          idsPermiso = [];
        }
        /* ====================== */
        /* |      TECNICO      | */
        /* ====================== */
        if (this.authenticationService.tipo == this.ROL_TECNICO) {
          console.log(this.authenticationService);

          idsPermiso = [10,357, 358, 359, 361,255,256,257,258,259,260,261,262,314,315,316,317,318];
          if (this.authenticationService.usuario == 'rtopich') {
            idsPermiso = [10,6,32,37,41,44,50,114,252,255,256,265,270,274,295,296,297,298,341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363,314,315,316,317,318];
          }
        }

        /* ====================== */
        /* |         WEB        | */
        /* ====================== */
        if (this.globals.modulos.indexOf(MODULOS.WEB) != -1) {
          idsPermiso.push(184);
          /* ====================== */
          /* |      DIRECTOR      | */
          /* ====================== */
          if (this.authenticationService.tipo == this.ROL_DIRECTOR) {
            idsPermiso.push(189);
          }
          /* ======================= */
          /* |    ADMINISTRADOR    | */
          /* ======================= */
          if (this.authenticationService.tipo == this.ROL_ADMINISTRADOR) {
            idsPermiso.push(189);
          }
        }

        /* ====================== */
        /* |     PRODUCCIÓN     | */
        /* ====================== */
        if (this.globals.modulos.indexOf(MODULOS.PRODUCCION) != -1) {
          idsPermiso.push(233, 234, 235, 236, 237, 238, 239, 240, 241, 243, 244, 245, 246,255);
        }

        observer.next({
          estado: 1,
          data: (new Permisos()).LISTADO.filter((permiso) => { return idsPermiso.includes(permiso.IdPermiso); })
        });
        observer.complete();
      }, 10);
    }).map((respuesta: any) => {
      if (respuesta.estado == 1 && respuesta.data) {
        this.flushPermisos();
        respuesta.data.forEach(permiso => {
          this.permisos.push(<Permiso>{ // guardar permisos tal cual llegan para el uso en el sistema
            IdPermiso: permiso.IdPermiso,
            permisoComponente: permiso.permisoComponente,
            permisoAction: permiso.permisoAction,
            permisoIdPermisoPadre: permiso.permisoIdPermisoPadre
          })
        });
      }

      this.permisosCargados = true;
      return this.formatearPermisos(); // retorna los permisos formateados para la libreria que adminsitra los permisos
    });
  }

  // true si tiene permiso, o false si no lo tiene
  // ademas si los permisos aún no se cargaron espera a que se carguen para verificar y retornar un valor
  tienePermiso(permisoComponente: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.intervalTienePermisosArray.push(
        <LlamadoPermisoAction>{ // siempre que los permisos aun no esten cargados, se consulta si ya fueron cargados cada un tiempo
          componente: permisoComponente,
          interval: this.intervalTienePermiso(resolve, permisoComponente)
        }
      );
    });
  }

  intervalTienePermiso(resolve: any, permisoComponente: string): any {
    if (this.permisosCargados) { // si los permisos estan cargados
      this.completarResolve(resolve, permisoComponente);

      return null;
    }

    return setInterval(() => { // si los permisos no estan cargados
      if (this.permisosCargados) { // si la busqueda detecta que se termino de buscar los permisos                        
        this.completarResolve(resolve, permisoComponente);

        // stop control, ya no es necesario seguir preguntando si los permisos fueron cargados
        this.cancelarPermisoInterval(this.intervalTienePermisosArray, permisoComponente);
      }
    }, 300);

  }

  // setear en el resolve los valores según corresponda
  completarResolve(resolve: any, permisoComponente: string): void {
    if (this.permisoAction(permisoComponente) != this.SIN_PERMISO) { // tiene permiso
      resolve(true);
    } else { // no tiene permiso
      resolve(false);
    }
  }

  // retorma un observable que busca el "contorller/action" que se debe ejecutar en el servidor
  // como estas acciones se traen tambien del servidor retornara la accion adecuada
  // una vez que los permisos fueron cargados de manera asincrónica
  // si los permisos aún no fueron cargados, se quedara esperando la respuesta con el "interval"
  getPermisoAction(permisoComponente: string): Observable<string> {
    return new Observable(
      (observer) => {
        this.intervalGetPermisosArray.push(
          <LlamadoPermisoAction>{ // siempre que los permisos aun no esten cargados, se consulta si ya fueron cargados cada un tiempo
            componente: permisoComponente,
            interval: this.intervalControlPermisos(observer, permisoComponente)
          }
        );
      }
    );
  }

  // interval que controla si estan los permisos
  // si no estan pregunta cada un tiempo si ya estan hasta que esten
  // si los permisos ya estaban cargados retorna directamente los permisos cargados
  intervalControlPermisos(observer: any, permisoComponente: string): any {
    if (this.permisosCargados) { // si no estoy buscando permisos puedo retornarlos sin generar una busqueda
      // permisos encontrados sin necesisdad de controlar con un loop
      this.completarObserver(observer, permisoComponente);

      return null;
    }

    return setInterval(() => {
      if (this.permisosCargados) { // si la busqueda detecta que se termino de buscar los permisos
        // permisos encontrados mediante busqueda
        this.completarObserver(observer, permisoComponente);

        // stop control, ya no es necesario seguir preguntando si los permisos fueron cargados
        this.cancelarPermisoInterval(this.intervalGetPermisosArray, permisoComponente);
      }
    }, 300);
  }

  // setear en el observer los eventos y sus valores segun corresponda
  completarObserver(observer: any, permisoComponente: string): void {
    var controllerAction = this.permisoAction(permisoComponente);
    if (controllerAction != this.SIN_PERMISO) {
      observer.next(controllerAction); // retornar el "contorller/action" buscado para realizar la consulta al servidor
    } else {  // si no se encuentra el action asociado al componente
      observer.error('No hay un controller/action asociado a su permiso');

    }

    observer.complete(); // avisar al padre del observer que se completo la busqueda
  }

  cancelarPermisoInterval(arrayDeLlamados: LlamadoPermisoAction[], permisoComponente: string): void {  //  quitar de la cola de llamados la obtencion del permiso
    arrayDeLlamados.forEach((llamado: LlamadoPermisoAction) => {
      if (llamado.componente == permisoComponente) {
        clearInterval(llamado.interval);
        return; // llamado cancelado
      }
    });
  }

  permisoAction(permisoComponente: string): string { // retorna el "contorller/action" en el servidor según el componente que realiza el llamado
    var permisoAction = this.SIN_PERMISO;

    this.permisos.forEach(permiso => {
      if (permiso.permisoComponente == permisoComponente) {
        permisoAction = permiso.permisoAction;
        return true; // dejamos de recorrer, encontramos el action del permiso
      }
    });

    return permisoAction;
  }
}