import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationService } from './providers/auth.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/pages/logueado/logueado.module#LogueadoModule',
    canActivate: [AuthenticationService]
  },
  {
    path: 'ingreso',
    loadChildren: 'app/pages/public/ingreso/ingreso.module#IngresoModule'
  },
  {
    path: 'tarea-detalle/:id',
    redirectTo: 'logueado/tareas/tarea-detalle/:id'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
