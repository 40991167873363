// VARIABLES GLOBALES
import { Injectable } from '@angular/core';

// ESTADOS POSIBLES DEL ADMINISTRADOR
export class ADMINISTRADOR_ESTADO {
  public static readonly EN_DESARROLLO = "EN_DESARROLLO";
  public static readonly MUESTRA_CLIENTE = "MUESTRA_CLIENTE";
  public static readonly PRODUCCION = "PRODUCCION";
}

// ESTADOS POSIBLES DEL SERVIDOR
export class SERVIDOR_ESTADO {
  public static readonly PC_DESARROLLADOR = "PC_DESARROLLADOR";
  public static readonly EN_DESARROLLO = "EN_DESARROLLO";
  public static readonly MUESTRA_CLIENTE = "MUESTRA_CLIENTE";
  public static readonly PRODUCCION = "PRODUCCION";
}

// TITULOS DE LAS EMPRESAS: usar minúscula, sin espacios
export class EMPRESAS_TITULO {
  public static readonly MIRON = "miron";
  public static readonly CAT = "cat";
  public static readonly ITEGOCRM = "itegocrm";
  public static readonly NOVA = "nova";
}

// modulos disponibles
export class MODULOS {
  public static readonly WEB = "WEB";
  public static readonly PRODUCCION = "PRODUCCION";
}

// VARIABLES DE CADA EMPRESA QUE USA EL SISTEMA
export class EMPRESA_DEL_SISTEMA {
  constructor(
    public titulo: string,
    public admin_dev: string,  // testing en el servidor para el desarrollo
    public admin_muestra_cliente: string, // testing de la version en el cliente
    public admin_produccion_nombre: string,  // version de produccion
    public server_pc_desarrollador: string,
    public server_desarrollo: string, // testing del servidor local
    public server_muestra_cliente: string, // testing del servidor para provar en el cliente
    public server_produccion: string,  // version de produccion
    public color_caracteristico: string,
    public logo_login: string, // logo de la pantalla de logueo
    public logo_login_size: string, // tamaño del logo en la pantalla de logueo
    public boton_home_logo: string,
    public boton_home_texto: string,
    public side_impresion: string,
    public impresion_header_extension: string,
    public impresion_header_size: string,
    public impresion_tabla_header_color: string,
    public impresion_tabla_header_color_contrast: string,
    public impresion_firma_extension: string,
    public impresion_footer_extension: string,
    public web_index_contadores_background_extension: string,
    public web_index_contadores_color: string,
    public favicon: string,
    public firma_email_color: string,
    public firma_email_empresa_nombre: string,
    public firma_email_direccion: string[],
    public firma_email_provincia: string,
    public firma_email_pais: string,
    public firma_email_telefono: string,
    public firma_email_web: string,
    public firma_email_imagenes: string[],
    public modulos: string[],
  ) { }
}

/*=============================================================*/
/* MODIFIQUE AQUÍ LAS VARIABLES DE COMPILACIÓN (transpilación) */
/*=============================================================*/
export class CONSTANTES_DE_COMPILACION {
  // título de la empresa para la que se compila: un valor entre los posibles de EMPRESAS_TITULO
  public static readonly EMPRESA_TITULO: string = EMPRESAS_TITULO.NOVA;

  // estado del administrador: un valor entre los posibles de ADMINISTRADOR_ESTADO
  // public static readonly ADMINISTRADOR_ESTADO: string = ADMINISTRADOR_ESTADO.EN_DESARROLLO;
  public static readonly ADMINISTRADOR_ESTADO: string = ADMINISTRADOR_ESTADO.PRODUCCION;
  // public static readonly ADMINISTRADOR_ESTADO: string = ADMINISTRADOR_ESTADO.MUESTRA_CLIENTE;

  // estado del servidor: un valor entre los posibles de SERVIDOR_ESTADO
  // public static readonly SERVIDOR_ESTADO: string = SERVIDOR_ESTADO.PC_DESARROLLADOR;
  public static readonly SERVIDOR_ESTADO: string = SERVIDOR_ESTADO.PRODUCCION;
  // public static readonly SERVIDOR_ESTADO: string = SERVIDOR_ESTADO.MUESTRA_CLIENTE;
}

export class SERVIDORES_DE_SISTEMA {
  // URL DE LOS SERVIDORES (ADMIN/WEB -> TEST/LOCAL/PRODUCCION)

  // public static readonly HOST_PC_DESARROLLADOR: string = 'http://localhost';
  // public static readonly HOST_PC_DESARROLLADOR: string = 'http://192.168.100.9';
  // public static readonly HOST_PC_DESARROLLADOR: string = 'http://192.168.0.30';
  public static readonly HOST_PC_DESARROLLADOR: string = 'https://demos.itego.com.ar';

  public static readonly HOST_TESTING: string = 'https://demos.itego.com.ar';
  public static readonly HOST_PRODUCCION: string = ''; // CAMBIA SEGÚN EL CLIENTE
  public static readonly ADMIN_TEST_HOST: string = 'https://demos.itego.com.ar';
  public static readonly ADMIN_PRODUCCION_HOST: string = ''; // CAMBIA SEGÚN EL CLIENTE

  public static SERVER_URL(empresa: EMPRESA_DEL_SISTEMA): string {
    switch (CONSTANTES_DE_COMPILACION.SERVIDOR_ESTADO) {
      case SERVIDOR_ESTADO.PC_DESARROLLADOR:
        return SERVIDORES_DE_SISTEMA.HOST_PC_DESARROLLADOR + empresa.server_pc_desarrollador;
      case SERVIDOR_ESTADO.EN_DESARROLLO:
        return SERVIDORES_DE_SISTEMA.HOST_TESTING + empresa.server_desarrollo;
      case SERVIDOR_ESTADO.MUESTRA_CLIENTE:
        return SERVIDORES_DE_SISTEMA.HOST_TESTING + empresa.server_muestra_cliente;
      case SERVIDOR_ESTADO.PRODUCCION:
        return SERVIDORES_DE_SISTEMA.HOST_PRODUCCION + empresa.server_produccion;
    }
  }

  public static ADMIN_URL(empresa: EMPRESA_DEL_SISTEMA): string {
    switch (CONSTANTES_DE_COMPILACION.ADMINISTRADOR_ESTADO) {
      case ADMINISTRADOR_ESTADO.EN_DESARROLLO:
        return SERVIDORES_DE_SISTEMA.ADMIN_TEST_HOST + empresa.admin_dev;
      case ADMINISTRADOR_ESTADO.MUESTRA_CLIENTE:
        return SERVIDORES_DE_SISTEMA.ADMIN_TEST_HOST + empresa.admin_muestra_cliente;
      case ADMINISTRADOR_ESTADO.PRODUCCION:
        return SERVIDORES_DE_SISTEMA.ADMIN_PRODUCCION_HOST + empresa.admin_produccion_nombre;
    }
  }
}

export class EMPRESAS_DEL_SISTEMA {
  // ARRAY CON TODAS LAS EMRPESAS CREADAS PARA ESTE SISTEMA
  public static EMPRESAS = [
    <EMPRESA_DEL_SISTEMA>{
      titulo: EMPRESAS_TITULO.CAT,
      admin_dev: "/adminmiron/",
      admin_muestra_cliente: "/admincat/",
      admin_produccion_nombre: "https://cat.itego.com.ar/admincat/",
      server_pc_desarrollador: "/servermiron/index.php/",
      server_desarrollo: "/servermiron/index.php/",
      server_muestra_cliente: "/servercat/index.php/",
      server_produccion: "https://cat.itego.com.ar/servercat/index.php/",
      color_caracteristico: "#548dd4",
      logo_login: "ic_logo_cat",
      logo_login_size: "70px",
      boton_home_logo: "ic_logo_cat",
      boton_home_texto: "",
      side_impresion: "",
      impresion_header_extension: "jpg",
      impresion_header_size: "120",
      impresion_tabla_header_color: "#548dd4",
      impresion_tabla_header_color_contrast: "black",
      impresion_firma_extension: "jpg",
      impresion_footer_extension: "jpg",
      web_index_contadores_background_extension: "",
      web_index_contadores_color: "",
      favicon: "ic_logo_cat.png",
      firma_email_color: "#082e46",
      firma_email_empresa_nombre: "CAT MIRON",
      firma_email_direccion: [
        "El Partenón 1637",
        "(B1839DIW) - Pque. Industrial 9 de Abril",
        "9 de Abril - Esteban Echeverria"
      ],
      firma_email_provincia: "Buenos Aires",
      firma_email_pais: "Argentina",
      firma_email_telefono: "+54 (11) 4693-9100",
      firma_email_web: "www.catsa.com.ar",
      firma_email_imagenes: [
        "imagen_1.jpg"
      ],
      modulos: []
    },
    <EMPRESA_DEL_SISTEMA>{
      titulo: EMPRESAS_TITULO.MIRON,
      admin_dev: "/adminmiron/",
      admin_muestra_cliente: "/adminmiron/",
      admin_produccion_nombre: "https://miron.itego.com.ar/adminmiron/",
      server_pc_desarrollador: "/servermiron/index.php/",
      server_desarrollo: "/servermiron/index.php/",
      server_muestra_cliente: "/servermiron/index.php/",
      server_produccion: "https://miron.itego.com.ar/servermiron/index.php/",
      color_caracteristico: "#bfbfbf",
      logo_login: "ic_logo_grande_miron",
      logo_login_size: "50px",
      boton_home_logo: "ic_log",
      boton_home_texto: "Miron",
      side_impresion: "side_miron.png",
      impresion_header_extension: "png",
      impresion_header_size: "140",
      impresion_tabla_header_color: "#e2e2e2",
      impresion_tabla_header_color_contrast: "black",
      impresion_firma_extension: "jpg",
      impresion_footer_extension: "jpg",
      web_index_contadores_background_extension: "jpg",
      web_index_contadores_color: "#960b0bad",
      favicon: "ic_logo_miron.png",
      firma_email_color: "#d81212",
      firma_email_empresa_nombre: "Electromecánica Brenta S.A.",
      firma_email_direccion: ["Blanco Encalada 734, Villa Martelli"],
      firma_email_provincia: "Buenos Aires",
      firma_email_pais: "Argentina",
      firma_email_telefono: "+54 11 4838 1596 / 97",
      firma_email_web: "http://www.transformadormiron.com.ar/",
      firma_email_imagenes: [
        'imagen_1.jpg',
        'imagen_2.png'
      ],
      modulos: [
        MODULOS.WEB,
        MODULOS.PRODUCCION,
      ]
    },
    <EMPRESA_DEL_SISTEMA>{
      titulo: EMPRESAS_TITULO.NOVA,
      admin_dev: "/adminmiron/",
      admin_muestra_cliente: "/adminnova/",
      admin_produccion_nombre: "https://nova.itego.com.ar/adminnova/",
      server_pc_desarrollador: "/servermiron/index.php/",
      server_desarrollo: "/servernova/index.php/",
      server_muestra_cliente: "/servernova/index.php/",
      server_produccion: "https://nova.itego.com.ar/servernova/index.php/",
      color_caracteristico: "#bfbfbf",
      logo_login: "ic_logo_nova",
      logo_login_size: "80px",
      boton_home_logo: "ic_logo_nova",
      boton_home_texto: "Nova",
      side_impresion: "side_miron.png",
      impresion_header_extension: "png",
      impresion_header_size: "70",
      impresion_tabla_header_color: "#e2e2e2",
      impresion_tabla_header_color_contrast: "black",
      impresion_firma_extension: "jpg",
      impresion_footer_extension: "jpg",
      web_index_contadores_background_extension: "jpg",
      web_index_contadores_color: "#960b0bad",
      favicon: "ic_logo_miron.png",
      firma_email_color: "#d81212",
      firma_email_empresa_nombre: "Electromecánica Brenta S.A.",
      firma_email_direccion: ["Blanco Encalada 734, Villa Martelli"],
      firma_email_provincia: "Buenos Aires",
      firma_email_pais: "Argentina",
      firma_email_telefono: "+54 11 4838 1596 / 97",
      firma_email_web: "http://www.transformadormiron.com.ar/",
      firma_email_imagenes: [
        'imagen_1.jpg',
        'imagen_2.png'
      ],
      modulos: [
        
      ]
    },
    <EMPRESA_DEL_SISTEMA>{
      titulo: EMPRESAS_TITULO.ITEGOCRM,
      admin_dev: "/adminmiron/",
      admin_muestra_cliente: "/adminmiron/",
      admin_produccion_nombre: "https://demos.itego.com.ar/itegocrm_itego",
      server_pc_desarrollador: "/serveritegocrm/index.php/",
      server_desarrollo: "/serveritegocrm/index.php/",
      server_muestra_cliente: "/serveritegocrm/index.php/",
      server_produccion: "https://demos.itego.com.ar/serveritegocrm_itego/index.php/",
      color_caracteristico: "#bfbfbf",
      logo_login: "ic_logo_grande_itegocrm",
      logo_login_size: "50px",
      boton_home_logo: "ic_logo_itegocrm",
      boton_home_texto: "ItegoCRM",
      side_impresion: "side_miron.png",
      impresion_header_extension: "png",
      impresion_header_size: "140",
      impresion_tabla_header_color: "#e2e2e2",
      impresion_tabla_header_color_contrast: "black",
      impresion_firma_extension: "jpg",
      impresion_footer_extension: "jpg",
      web_index_contadores_background_extension: "jpg",
      web_index_contadores_color: "#960b0bad",
      favicon: "ic_logo_itegocrm.png",
      firma_email_color: "#d81212",
      firma_email_empresa_nombre: "Itego",
      firma_email_direccion: ["Santa Rosa 591, Castelari"],
      firma_email_provincia: "Buenos Aires",
      firma_email_pais: "Argentina",
      firma_email_telefono: "+54 11 4838 1596 / 97",
      firma_email_web: "http://www.itego.com.ar/",
      firma_email_imagenes: [
        'imagen_1.jpg',
        'imagen_2.png'
      ],
      modulos: [
        //MODULOS.WEB,
      ]
    }
  ];

  /**
   * Retorna los datos de la empresa para la que se compila el sistema
   */
  public static EMPRESA(): EMPRESA_DEL_SISTEMA {
    var index = this.EMPRESAS.length;
    while (index--) {
      if (this.EMPRESAS[index].titulo == CONSTANTES_DE_COMPILACION.EMPRESA_TITULO) {
        return this.EMPRESAS[index];
      }
    }

    return null;
  }
}

@Injectable()
export class Globals {
  public readonly _EMPRESAS = EMPRESAS_TITULO; // títulos de todas las empresas accesibles desde globals

  public readonly webadmin: string = SERVIDORES_DE_SISTEMA.ADMIN_URL(EMPRESAS_DEL_SISTEMA.EMPRESA()); // URL ABSOLUTA DEL ADMIN
  public readonly webserver: string = SERVIDORES_DE_SISTEMA.SERVER_URL(EMPRESAS_DEL_SISTEMA.EMPRESA()); // URL DEL SERVER
  public readonly empresa: string = EMPRESAS_DEL_SISTEMA.EMPRESA().titulo; // TITULO EMPRESA COMPILADA
  public readonly color_caracteristico: string = EMPRESAS_DEL_SISTEMA.EMPRESA().color_caracteristico; // COLOR DE LA EMPRESA   
  public readonly logo_login: string = EMPRESAS_DEL_SISTEMA.EMPRESA().logo_login;
  public readonly logo_login_size: string = EMPRESAS_DEL_SISTEMA.EMPRESA().logo_login_size;
  public readonly boton_home_logo: string = EMPRESAS_DEL_SISTEMA.EMPRESA().boton_home_logo;
  public readonly boton_home_texto: string = EMPRESAS_DEL_SISTEMA.EMPRESA().boton_home_texto;
  public readonly side_impresion: string = EMPRESAS_DEL_SISTEMA.EMPRESA().side_impresion;
  public readonly modulos: string[] = EMPRESAS_DEL_SISTEMA.EMPRESA().modulos;

  // impresión: cotización
  public readonly impresion_header_extension: string = EMPRESAS_DEL_SISTEMA.EMPRESA().impresion_header_extension;
  public readonly impresion_header_size: string = EMPRESAS_DEL_SISTEMA.EMPRESA().impresion_header_size;
  public readonly impresion_tabla_header_color: string = EMPRESAS_DEL_SISTEMA.EMPRESA().impresion_tabla_header_color;
  public readonly impresion_tabla_header_color_contrast: string = EMPRESAS_DEL_SISTEMA.EMPRESA().impresion_tabla_header_color_contrast;
  public readonly impresion_firma_extension: string = EMPRESAS_DEL_SISTEMA.EMPRESA().impresion_firma_extension;
  public readonly impresion_footer_extension: string = EMPRESAS_DEL_SISTEMA.EMPRESA().impresion_footer_extension;

  // web: index
  public readonly web_index_contadores_background_extension: string = EMPRESAS_DEL_SISTEMA.EMPRESA().web_index_contadores_background_extension;
  public readonly web_index_contadores_color: string = EMPRESAS_DEL_SISTEMA.EMPRESA().web_index_contadores_color;

  // favicon
  public readonly favicon: string = EMPRESAS_DEL_SISTEMA.EMPRESA().favicon;

  // productos y servicios
  public readonly tipo_oferta_productos: number = 1;
  public readonly tipo_oferta_servicios: number = 2;

  // firma de los emails
  public readonly firma_email_color: string = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_color;
  public readonly firma_email_empresa_nombre: string = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_empresa_nombre;
  public readonly firma_email_direccion: string[] = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_direccion;
  public readonly firma_email_provincia: string = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_provincia;
  public readonly firma_email_pais: string = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_pais;
  public readonly firma_email_telefono: string = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_telefono;
  public readonly firma_email_web: string = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_web;
  public readonly firma_email_imagenes: string[] = EMPRESAS_DEL_SISTEMA.EMPRESA().firma_email_imagenes;
}